<div fxLayout="column">
  <div *ngIf="!loaded" class="loader-container" fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
  <div [hidden]="!loaded" class="iframe-container" fxFlex="1 1 100%">
    <iframe [src]="url" frameborder="0" scroll="yes" scrolling="yes" (load)="onIframeLoaded()"></iframe>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="close()">{{ 'SHARED.CLOSE' | translate }}</button>
  </div>
</div>
