<div class="card-page">
  <mat-card>
    <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="24px" class="m-16">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="24px">
        <app-full-logo></app-full-logo>

        <div *ngIf="(webRTCService.permissionsStatus | async) === DevicePermission.NoPermission" class="warning big"
          fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="big ml-16 mr-16" fxFlex="0 0 auto">warning</mat-icon>
          <p [innerHTML]="'PARAMETER.PERMISSION_DENIED' | translate"></p>
        </div>

        <div *ngIf="(webRTCService.permissionsStatus | async) === DevicePermission.NoDevicesFound" class="attention big"
          fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="big ml-16 mr-16" fxFlex="0 0 auto">report</mat-icon>
          <p [innerHTML]="'PARAMETER.NO_DEVICES_FOUND' | translate"></p>
        </div>

        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center start" fxLayoutAlign.xs="start stretch">
          <div fxFlex="1 1 50%" class="parameter-wrapper p-12">
            <h4 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>settings</mat-icon>&nbsp;{{ 'TEST.PARAMETER_TITLE' | translate }}
            </h4>
            <app-parameter></app-parameter>
          </div>

          <mat-divider fxHide fxShow.xs></mat-divider>

          <div fxFlex="1 1 50%" class="test-wrapper p-12">
            <h4 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>wifi</mat-icon>&nbsp;{{ 'TEST.TITLE' | translate }}
            </h4>

            <div *ngIf="isTesting;else noTest">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-8">
                <div>{{ testStatus | translate }}</div>
                <div *ngIf="testRemainingTime">
                  {{ testRemainingTime > 0 ? ('~' + ('TEST.TIME_UNTIL_DONE' | translate:{i: testRemainingTime})) : 'TEST.TIME_ALMOST_DONE' | translate }}
                </div>
              </div>
              <mat-progress-bar [mode]="testMode" color="primary" [value]="testProgress" class="mb-8">
              </mat-progress-bar>
            </div>

            <ng-template #noTest>
              <div *ngIf="isResult !== undefined;else noResult" class="result mt-16 bordered-container" fxLayout="colum"
                fxLayoutAlign="space-around center">
                <p fxFlex="0 0 33%" [style.color]="isResult ? 'green' : 'red'">
                  <mat-icon class="big">{{ isResult ? 'done' : 'warning' }}</mat-icon><br />
                  {{ resultString | translate }}
                </p>
                <p fxFlex="0 0 33%"
                  *ngIf="resultString !== 'TEST.RESULT.CONNECTIVITY_ERROR' && (resultSpeedVideo != undefined || resultPacketLostVideo != undefined)">
                  <strong>Video</strong><br />
                  <span *ngIf="resultSpeedVideo != undefined"
                    style="font-size: small;">{{ ('TEST.BANDWIDTH' | translate:{ i:((resultSpeedVideo/1000) | number:'1.0-0') }) }}</span><br />
                  <span *ngIf="resultPacketLostVideo != undefined"
                    style="font-size: x-small;">{{ ('TEST.PAQUET_LOSS' | translate:{ i:(resultPacketLostVideo*100 | number:'1.0-2') }) }}</span>
                </p>
                <p fxFlex="0 0 33%"
                  *ngIf="resultString !== 'TEST.RESULT.CONNECTIVITY_ERROR' && (resultSpeedAudio != undefined || resultPacketLostAudio != undefined)">
                  <strong>Audio</strong><br />
                  <span *ngIf="resultSpeedAudio != undefined"
                    style="font-size: small;">{{ ('TEST.BANDWIDTH' | translate:{ i:((resultSpeedAudio/1000) | number:'1.0-0') }) }}</span><br />
                  <span *ngIf="resultPacketLostAudio != undefined"
                    style="font-size: x-small;">{{ ('TEST.PAQUET_LOSS' | translate:{ i:(resultPacketLostAudio*100 | number:'1.0-2') }) }}</span>
                </p>
              </div>
            </ng-template>

            <ng-template #noResult>
              <div class="no-result mt-16 bordered-container">
                <p [style.opacity]="(webRTCService.permissionsStatus | async) === 'Ok' ? '1': '0.5'">
                  <mat-icon class="big">wifi</mat-icon><br />
                  {{ 'TEST.NO_TEST_DONE' | translate }}
                </p>
              </div>
            </ng-template>

            <div class="mt-16">
              <button class="tall" *ngIf="!isTesting" (click)="launchTest()"
                [disabled]="(webRTCService.permissionsStatus | async) === DevicePermission.NoPermission || (webRTCService.permissionsStatus | async) === DevicePermission.NoDevicesFound || isTesting"
                type="button" mat-stroked-button color="primary">
                {{ 'TEST.START_TEST_BUTTON' | translate }}
              </button>
              <button class="tall" *ngIf="isTesting" (click)="stopTest()" [disabled]="testStatus !== 'TEST.TESTING'"
                type="button" mat-stroked-button color="primary">
                {{ 'TEST.STOP_TEST_BUTTON' | translate }}
              </button>
            </div>
            <button (click)="startExpertise()"
              [disabled]="(webRTCService.permissionsStatus | async) === DevicePermission.NoPermission || (webRTCService.permissionsStatus | async) === DevicePermission.NoDevicesFound || isTesting"
              type="button" mat-raised-button color="primary" class="tall mt-32 w-100-p">
              {{ 'TEST.START_EXPERTISE_BUTTON' | translate }}
            </button>
          </div>
        </div>
      </div>

      <app-copyright></app-copyright>
    </div>
  </mat-card>
</div>
