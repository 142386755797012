import { ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
Sentry.init({
    dsn: 'https://80ea97f2731c43c4bf908d364f73b558@sentry.2dia.fr/17',
    environment: environment.name,
    release: '' + require('../../package.json').name + '@' + environment.version
});
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        if (error.stack && error.stack.indexOf('/analytics.js') !== -1) {
            return;
        }
        Sentry.captureException(error.originalError || error);
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
var ɵ0 = (createTranslateLoader);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
