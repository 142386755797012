/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pointer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pointer.component";
import * as i3 from "../../../services/webrtc.service";
var styles_PointerComponent = [i0.styles];
var RenderType_PointerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PointerComponent, data: {} });
export { RenderType_PointerComponent as RenderType_PointerComponent };
export function View_PointerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "dot"]], [[2, "expert", null], [2, "guest", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "expert"); var currVal_1 = (_co.type === "guest"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PointerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pointer", [], null, null, null, View_PointerComponent_0, RenderType_PointerComponent)), i1.ɵdid(1, 49152, null, 0, i2.PointerComponent, [i3.WebRTCService], null, null)], null, null); }
var PointerComponentNgFactory = i1.ɵccf("app-pointer", i2.PointerComponent, View_PointerComponent_Host_0, { type: "type" }, { takePhotoClicked: "takePhotoClicked", gotoGalleryClicked: "gotoGalleryClicked", switchCameraClicked: "switchCameraClicked", toggleMicrophoneClicked: "toggleMicrophoneClicked" }, []);
export { PointerComponentNgFactory as PointerComponentNgFactory };
