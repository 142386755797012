import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BrowserCompatibilityService } from '../services/browser-compatibility.service';

@Injectable()
export class BrowserCompatibilityGuard implements CanActivate {
  constructor(
    private router: Router,
    private browserCompatibilityService: BrowserCompatibilityService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isCompatible = this.browserCompatibilityService.isBrowserCompatible();
    if (next.routeConfig.path === 'incompatible-browser') {
      if (isCompatible) {
        this.router.navigate(['']);
      }
      return !isCompatible;
    } else {
      if (!isCompatible) {
        this.router.navigate(['incompatible-browser']);
      }
      return isCompatible;
    }
  }
}
