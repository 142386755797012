import { NgModule } from '@angular/core';
import { LegalNoticeComponent } from './legal-notice.component';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    LegalNoticeComponent
  ],
  imports: [
    SharedModule
  ]
})
export class LegalNoticeModule { }
