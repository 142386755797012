import { Component, Input } from '@angular/core';
import { ThemeService } from '../../../services/theme.service';
const { version: appVersion } = require('../../../../../package.json');

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {
  @Input() legalNotice = true;
  @Input() showVersion = true;
  version = appVersion;
  currentYear: number = (new Date()).getFullYear();

  constructor(
    public themeService: ThemeService
  ) { }
}
