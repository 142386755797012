/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./publisher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./publisher.component";
import * as i3 from "../../../services/webrtc.service";
var styles_PublisherComponent = [i0.styles];
var RenderType_PublisherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublisherComponent, data: {} });
export { RenderType_PublisherComponent as RenderType_PublisherComponent };
export function View_PublisherComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { publisherDiv: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["publisherDiv", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_PublisherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-publisher", [], null, null, null, View_PublisherComponent_0, RenderType_PublisherComponent)), i1.ɵdid(1, 4374528, null, 0, i2.PublisherComponent, [i3.WebRTCService], null, null)], null, null); }
var PublisherComponentNgFactory = i1.ɵccf("app-publisher", i2.PublisherComponent, View_PublisherComponent_Host_0, {}, {}, []);
export { PublisherComponentNgFactory as PublisherComponentNgFactory };
