import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  OnInit,
  NgZone,
  ViewChildren,
  QueryList
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../services/utils.class';
import { WebRTCService, DevicePermission } from '../../services/webrtc.service';
import { ApiService } from '../../services/api.service';
import { Room } from '../../models/api/room.interface';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { BasicDialogComponent, BasicDialogData } from '../shared-components/basic-dialog/basic-dialog.component';
import { environment } from '../../../environments/environment';
import { Subscription, BehaviorSubject } from 'rxjs';
import { LadDialogComponent, LadDialogData } from './lad-dialog/lad-dialog.component';
import { BrowserCompatibilityService } from '../../services/browser-compatibility.service';
import { DeviceInfo } from '../../models/api/device-info.interface';
import { NgxToastedComponent, Toast } from 'ngx-toasted';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { PhoneService } from 'src/app/services/phone.service';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss'],
  providers: [WebRTCService]
})
export class ExpertiseComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription[] = [];
  locationWatchId = undefined;
  lastLat = undefined;
  lastLong = undefined;

  private hasBeenInit = false;
  private name: string;
  private phone: string;
  room: Room;
  private busyUploading = false;

  @ViewChild('gallery', { static: true }) galleryInput: ElementRef;
  @ViewChild('pointersContainer', { static: false }) pointersContainer: ElementRef;
  @ViewChild('toastContainer', { read: NgxToastedComponent, static: false }) toastContainer: NgxToastedComponent;
  @ViewChild('subscribersContainer', { static: true }) subscribersContainer: ElementRef;
  @ViewChildren('subscribers') subscribersComponent: QueryList<SubscriberComponent>;

  pointers: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private phoneService: PhoneService,
    private translateService: TranslateService,
    private browserCompatibilityService: BrowserCompatibilityService,
    public webRTCService: WebRTCService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {
    try {
      const base64 = this.route.snapshot.queryParams.u;
      const name = this.route.snapshot.queryParams.name;
      const phone = this.route.snapshot.queryParams.phone;
      if (base64) {
        const userInfo = Utils.deserializeNameAndPhone(base64);
        if (userInfo.name && userInfo.phone) {
          this.name = userInfo.name;
          this.phone = this.phoneService.formatPhoneForApplication(userInfo.phone);
        } else {
          throw new Error('Bad user info');
        }
      } else if (name && phone) {
        this.name = name;
        this.phone = this.phoneService.formatPhoneForApplication(phone);
      } else {
        throw new Error('Missing user info');
      }
    } catch (error) {
      this.router.navigate(['login']);
      return;
    }

    this.apiService.getExpertiseRoom(this.phone, this.name).subscribe((room: Room) => {
      this.room = room;
      this.afterGettingRoom();
    }, (err) => {
      let message;
      switch (err ? err.status : -1) {
        case 403:
          message = this.translateService.instant('EXPERTISE.API_ERRORS.403');
          break;
        default:
          message = this.translateService.instant('EXPERTISE.API_ERRORS.OTHER');
          break;
      }

      const d = this.dialog.open(BasicDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateService.instant('EXPERTISE.API_ERRORS.TITLE'),
          content: message,
          positiveButton: this.translateService.instant('SHARED.OK')
        } as BasicDialogData
      });

      d.afterClosed().subscribe(result => {
        this.router.navigate(['login']);
      });
      this.ref.detectChanges();
    });
  }

  ngOnInit(): void {
    const doc = document.documentElement;
    doc.style.setProperty('--overflow-body', `hidden`);
    doc.style.setProperty('--position-body', `fixed`);

    this.subscriptions.push(
      this.webRTCService.onSessionDisconnectedEmitter.subscribe((event) => {
        if (event.reason === 'forceDisconnected') {
          this.ngZone.run(() => this.endExpertise(true));
        }
      })
    );
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);

    this.subscriptions.push(
      this.webRTCService.onStreamCreatedEmitter.subscribe((stream: OT.Stream) => {
        // Prevent overlapping subscribers
        const component = this.subscribersComponent.find((item: SubscriberComponent) => item.stream.streamId === stream.streamId);
        if (component && component.element && component.element.nativeElement) {
          const nativeElement = component.element.nativeElement;
          let componentRect: DOMRect = nativeElement.getBoundingClientRect();
          const rects: DOMRect[] = this.subscribersComponent
            .filter((item: SubscriberComponent) => item.stream.streamId !== stream.streamId)
            .map(
              (item: SubscriberComponent) => {
                return item.element && item.element.nativeElement ?
                item.element.nativeElement.getBoundingClientRect() :
                null;
              }
            )
            .filter((item) => item !== null);

          rects.forEach(rect => {
            if (Utils.doesRectsIntersect(componentRect, rect)) {
              nativeElement.style.top = (rect.top + rect.height + 8) + 'px';
              componentRect = nativeElement.getBoundingClientRect();
            }
          });
        }
      })
    );

    this.subscriptions.push(
      this.webRTCService.onSignalEventEmitter.subscribe((event) => {
        this.ngZone.run(() => {
          this.handleSignal(event);
        });
      })
    );

    this.subscriptions.push(
      this.webRTCService.onConnectionCreatedEmitter.subscribe((event) => {
        const session = this.webRTCService.currentSessionObject;
        if (session && session.connection.connectionId !== event.connection.connectionId) {
          if (event.connection.data === 'type=expert') {
            this.showToast('connection-expert', 'EXPERTISE.TOAST.EXPERT_CONNECTED', 4000, 'success');
          } else {
            this.showToast('connection-user', 'EXPERTISE.TOAST.USER_CONNECTED', 2000, 'info');
          }
          this.sendDeviceInfo(this.lastLat, this.lastLong, true, false);
        }
      })
    );

    this.subscriptions.push(
      this.webRTCService.onConnectionDestroyedEmitter.subscribe((event) => {
        const session = this.webRTCService.currentSessionObject;
        if (session && session.connection.connectionId !== event.connection.connectionId) {
          if (event.connection.data === 'type=expert') {
            this.showToast('connection-expert', 'EXPERTISE.TOAST.EXPERT_DISCONNECTED', 0, 'error');
          } else {
            this.showToast('connection-user', 'EXPERTISE.TOAST.USER_DISCONNECTED', 2000, 'info');
          }
        }
      })
    );

    this.subscriptions.push(
      this.webRTCService.onSessionConnectedEmitter.subscribe((event) => {
        this.showToast('connection-self', 'EXPERTISE.TOAST.CONNECTED', 1000, 'success');
      })
    );

    this.subscriptions.push(
      this.webRTCService.onSessionReconnectingEmitter.subscribe((event) => {
        this.showToast('connection-self', 'EXPERTISE.TOAST.RECONNECTING', 0, 'error');
      })
    );

    this.subscriptions.push(
      this.webRTCService.onSessionReconnectedEmitter.subscribe((event) => {
        this.showToast('connection-self', 'EXPERTISE.TOAST.RECONNECTED', 2000, 'success');
      })
    );
  }

  ngOnDestroy(): void {
    const doc = document.documentElement;
    doc.style.setProperty('--overflow-body', `auto`);
    doc.style.setProperty('--position-body', `auto`);

    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    if (this.locationWatchId) {
      navigator.geolocation.clearWatch(this.locationWatchId);
    }
  }

  private afterGettingRoom() {
    this.showToast('connection-expert', 'EXPERTISE.TOAST.PLEASE_WAIT_FOR_EXPERT', 0, 'info');

    this.subscriptions.push(
      this.webRTCService.permissionsStatus.subscribe(res => {
        if (res === DevicePermission.Ok && !this.hasBeenInit) {
          this.hasBeenInit = true;
          this.startExpertise();
        } else if (res === DevicePermission.NoDevicesFound) {
          const dialog = this.dialog.open(BasicDialogComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('EXPERTISE.DIALOG_NO_DEVICE.TITLE'),
              content: this.translateService.instant('EXPERTISE.DIALOG_NO_DEVICE.CONTENT'),
              positiveButton: this.translateService.instant('SHARED.OK')
            } as BasicDialogData
          });

          dialog.afterClosed().subscribe(result => {
            this.router.navigate(['login']);
          });

          this.ref.detectChanges();
        } else if (res === DevicePermission.NoPermission) {
          const dialog = this.dialog.open(BasicDialogComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('EXPERTISE.DIALOG_NO_PERMISSION.TITLE'),
              content: this.translateService.instant('EXPERTISE.DIALOG_NO_PERMISSION.CONTENT'),
              negativeButton: this.translateService.instant('SHARED.QUIT'),
              positiveButton: this.translateService.instant('SHARED.REFRESH')
            } as BasicDialogData
          });

          dialog.afterClosed().subscribe(result => {
            if (result) {
              window.document.location.reload();
            } else {
              this.router.navigate(['login']);
            }
          });

          this.ref.detectChanges();
        }
      })
    );
  }

  private startExpertise() {
    try {
      this.webRTCService.setRoom(environment.opentok.keySession, this.room).then((session: OT.Session) => {
        this.subscriptions.push(
          this.webRTCService.currentStreams.subscribe((streams) => {
            if (!(this.ref as any).destroyed) {
              this.ref.detectChanges();
            }
          })
        );

        this.webRTCService.connect().then(() => {
          console.log('Ready !');
          this.onReady();
        }, (err) => {
          console.error(err);
          throw err;
        });
      }, (err) => {
        console.error(err);
        throw err;
      });
    } catch (error) {
      const dialog = this.dialog.open(BasicDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateService.instant('EXPERTISE.API_ERRORS.TITLE'),
          content: this.translateService.instant('EXPERTISE.API_ERRORS.OTHER'),
          positiveButton: this.translateService.instant('SHARED.OK')
        } as BasicDialogData
      });
      dialog.afterClosed().subscribe(result => {
        this.router.navigate(['login']);
      });
      this.ref.detectChanges();
    }
  }

  private onReady() {
    this.sendDeviceInfo(null, null, false, true);

    let lastSentTime;
    this.locationWatchId = navigator.geolocation.watchPosition(resp => {
      if (resp && resp.coords && resp.coords.latitude && resp.coords.longitude) {
        this.lastLat = resp.coords.latitude;
        this.lastLong = resp.coords.longitude;
        const currentTime = + new Date();
        if (!lastSentTime || currentTime > lastSentTime + 10000) {
          lastSentTime = currentTime;
          this.sendDeviceInfo(resp.coords.latitude, resp.coords.longitude);
        }
      }
    },
      err => {
        console.error(err);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }

  public endExpertise(force: boolean = false) {
    if (force) {
      this.router.navigate(['post-expertise']);
      this.ref.detectChanges();
      return;
    }

    const dialog = this.dialog.open(BasicDialogComponent, {
      data: {
        title: this.translateService.instant('EXPERTISE.DIALOG_STOP.TITLE'),
        content: this.translateService.instant('EXPERTISE.DIALOG_STOP.CONTENT'),
        negativeButton: this.translateService.instant('EXPERTISE.DIALOG_STOP.NEGATIVE'),
        positiveButton: this.translateService.instant('EXPERTISE.DIALOG_STOP.POSITIVE')
      } as BasicDialogData
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['post-expertise']);
      } else {
        dialog.close();
      }
    });
    this.ref.detectChanges();
  }

  private handleSignal(event) {
    switch (event.type) {
      case 'signal:pointer':
        if (event.data && typeof event.data === 'string') {
          try {
            event.data = JSON.parse(event.data);
          } catch (err) {
            console.error(err);
          }
        }

        let type = 'other';
        if (event.from && event.from.data) {
          switch (event.from.data) {
            case 'type=expert':
              type = 'expert';
              break;
            case 'type=guest':
              type = 'guest';
              break;
          }
        }

        if (event.data && event.data.x && event.data.y) {
          this.createPointer(event.data.x, event.data.y, type);
        }
        break;
      case 'signal:clear':
        if (event.data && typeof event.data === 'string') {
          try {
            event.data = JSON.parse(event.data);
          } catch (err) {
            console.error(err);
          }
        }

        this.clearPointers(event.data.sendBy);
        break;
      case 'signal:capture':
        if (event.data === 'force') {
          this.takePhoto();
        } else {
          this.askGallery();
        }
        break;
      case 'signal:la':
        this.openLAD(event.data);
        break;
      case 'signal:flash':
        console.warn('Flash functionalitie is not available for web devices');
        break;
    }
  }

  private createPointer(x, y, type) {
    const container = this.pointersContainer.nativeElement;
    const rect = container.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const xFactor = width / 320;
    const yFactor = height / 480;

    const calculatedX = x * xFactor;
    const calculatedY = y * yFactor;

    // Code if X & Y was relative coordinate from 0 to 1 (ex: { x: 0.5, y: 0.5 } == middle of the video)
    // const videoPositions = this.getFluxVideoRect();
    // if (!videoPositions) {
    //   return;
    // }

    // const calculatedX = (videoPositions.left + x * videoPositions.width) - 8;
    // const calculatedY = (videoPositions.top + y * videoPositions.height) - 8;

    let newVal = this.pointers.value;
    if (!newVal) {
      newVal = [];
    }

    newVal.push({ x: Math.trunc(calculatedX), y: Math.trunc(calculatedY), type });

    this.pointers.next(newVal);

    this.ref.detectChanges();
  }

  private clearPointers(type) {
    switch (type) {
      case 'expert':
        this.pointers.next(this.pointers.value.filter((p) => p.type !== 'expert'));
        break;
      case 'guest':
        this.pointers.next(this.pointers.value.filter((p) => p.type !== 'guest'));
        break;
      default:
        this.pointers.next([]);
        break;
    }
  }

  private openLAD(url) {
    const dialog = this.dialog.open(LadDialogComponent, {
      disableClose: true,
      minWidth: '95%',
      minHeight: '95%',
      maxWidth: '95%',
      maxHeight: '95%',
      width: '95%',
      height: '95%',
      data: {
        url
      } as LadDialogData
    });

    dialog.afterClosed().subscribe(result => { });
    this.ref.detectChanges();
  }

  public takePhoto() {
    if (this.busyUploading) {
      return;
    }
    this.busyUploading = true;

    try {
      const publisher = this.webRTCService.currentPublisherObject;
      if (!publisher) {
        console.error('Tried to take photo while no publisher has been initialized');
        this.busyUploading = false;
        return;
      }

      const base64 = publisher.getImgData(); // Doc say its always a PNG
      const file: File = Utils.dataURLtoFile(
        'data:image/png;base64,' + base64,
        Utils.generateUuid() + '.png'
      );

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENDING', 0, 'info');

      this.apiService.sendImage(this.phone, file, false).subscribe((res) => {
        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENT', 2000, 'success');
      }, (err) => {
        console.error(err);
        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
      });
    } catch (err) {
      console.error(err);
      this.busyUploading = false;

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
    }
  }

  public askGallery() {
    this.dialog.open(BasicDialogComponent, {
      data: {
        title: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.TITLE'),
        content: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.CONTENT'),
        negativeButton: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.NEGATIVE'),
        positiveButton: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.POSITIVE'),
        elementToClick: this.galleryInput.nativeElement
      } as BasicDialogData
    });

    this.ref.detectChanges();
  }

  public gotoGallery() {
    this.galleryInput.nativeElement.click();
  }

  public resumeFromGallery(event) {
    if (this.busyUploading) {
      return;
    }
    this.busyUploading = true;

    try {
      const file: File = event.target.files[0];
      this.galleryInput.nativeElement.value = '';

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENDING', 0, 'info');

      this.apiService.sendImage(this.phone, file, true).subscribe((res) => {
        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENT', 2000, 'success');
      }, (err) => {
        console.error(err);

        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
      });
    } catch (err) {
      console.error(err);
      this.busyUploading = false;

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
    }
  }

  public switchCamera() {
    const publisher = this.webRTCService.currentPublisherObject;
    if (!publisher) {
      console.error('Tried to switch camera while no publisher has been initialized');
      return;
    }

    publisher.cycleVideo();
  }

  public toggleMicrophone() {
    this.webRTCService.toggleAudio();
  }

  private getFluxVideoRect(): any {
    // ONLY WORK WITH FITMODE: contain

    const publisher = this.webRTCService.currentPublisherObject;
    if (!publisher) {
      return null;
    }

    const rectVideoElement = publisher.element.getBoundingClientRect();
    const aspectRatioFlux = publisher.videoWidth() / publisher.videoHeight();
    const aspectRatioVideoElement = rectVideoElement.width / rectVideoElement.height;

    // Code with orientation:
    // const orientation = '0';
    // const aspectRatioFlux = orientation === '90' || orientation === '270' ?
    // publisher.videoHeight() / publisher.videoWidth() :
    // publisher.videoWidth() / publisher.videoHeight();
    // const aspectRatioVideoElement = orientation === '90' || orientation === '270' ?
    // rectVideoElement.height / rectVideoElement.width :
    // rectVideoElement.width / rectVideoElement.height;

    let realWidthFlux;
    let realHeightFlux;
    let realXFlux;
    let realYFlux;
    if (aspectRatioFlux > aspectRatioVideoElement) {
      realWidthFlux = rectVideoElement.width;
      realHeightFlux = realWidthFlux / aspectRatioFlux;
      realXFlux = rectVideoElement.left;
      realYFlux = (rectVideoElement.height - realHeightFlux) / 2 + rectVideoElement.top;
    } else {
      realHeightFlux = rectVideoElement.height;
      realWidthFlux = realHeightFlux * aspectRatioFlux;
      realYFlux = rectVideoElement.top;
      realXFlux = (rectVideoElement.width - realWidthFlux) / 2 + rectVideoElement.left;
    }
    return new DOMRect(realXFlux, realYFlux, realWidthFlux, realHeightFlux);
  }

  private showToast(id: string = null, message: string, duration: number = 2000, type: string = 'info') {
    this.ngZone.run(() => {
      const toast = {
        id,
        message: this.translateService.instant(message),
        duration,
        type,
      } as Toast;

      this.toastContainer.addToast.emit(toast);
    });
  }

  private sendDeviceInfo(x: number = null, y: number = null, withSignalCall: boolean = true, withApiCall: boolean = true) {
    if (withSignalCall) {
      try {
        const session = this.webRTCService.currentSessionObject;
        if (session) {
          session.signal({
            type: 'platform',
            data: 'web',
          }, null);

          session.signal({
            type: 'device',
            data: this.browserCompatibilityService.getDeviceName(),
          }, null);

          session.signal({
            type: 'battery',
            data: this.translateService.instant('EXPERTISE.SIGNALS.BATTERY_UNKNOWN'),
          }, null);

          session.signal({
            type: 'network',
            data: this.translateService.instant('EXPERTISE.SIGNALS.NETWORK_UNKNOWN'),
          }, null);

          if (x && y) {
            session.signal({
              type: 'gps',
              data: x + ':' + y,
            }, null);
          }

        }
      } catch (err) {
        console.error(err);
      }
    }

    if (withApiCall) {
      this.apiService.sendDeviceInfo(
        this.phone,
        {
          plateform: 'Web',
          signal: '-1',
          battery: 'unknown',
          connection: 'Unknown',
          phoneType: this.browserCompatibilityService.getDeviceName(),
          coords: {
            x,
            y
          }
        } as DeviceInfo
      ).subscribe((res) => {
        console.log(res);
      }, (err) => {
        console.error(err);
      });
    }
  }

  public onSubscriberDragStarted(stream: OT.Stream) {
    this.webRTCService.putStreamOnTop(stream);
  }
}
