import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss']
})
export class BasicDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData
  ) {}

  negativeClick() {
    this.dialogRef.close(false);
  }

  positiveClick() {
    this.dialogRef.close(true);
  }
}

export interface BasicDialogData {
  title: string;
  content: string;
  negativeButton?: string;
  positiveButton: string;
  elementToClick?: any;
}
