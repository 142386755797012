import { NgModule } from '@angular/core';
import { IncompatibleBrowserComponent } from './incompatible-browser.component';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    IncompatibleBrowserComponent
  ],
  imports: [
    SharedModule
  ]
})
export class IncompatibleBrowserModule {

}
