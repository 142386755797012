<div>
  <div class="publisher-container">
    <app-publisher *ngIf="room"></app-publisher>
  </div>
  <div #pointersContainer class="pointers-container">
    <app-pointer *ngFor="let pointer of (pointers | async)"
    [style.top]="pointer.y + 'px'" [style.left]="pointer.x + 'px'" [type]="pointer.type"></app-pointer>
  </div>
  <div #subscribersContainer class="subscribers-container">
    <app-subscriber *ngFor="let stream of (webRTCService.currentStreams | async)"
      #subscribers
      [stream]="stream"
      cdkDrag cdkDragBoundary=".subscribers-container" (cdkDragStarted)="onSubscriberDragStarted(stream)">
    </app-subscriber>
  </div>
  <div class="top-actions-container" fxLayoutGap="16px">
    <button fxFlex="0 0 auto" type="button" class="tall stop-button" mat-raised-button color="warn" (click)="endExpertise()">
      <mat-icon matPrefix class="mr-8">call_end</mat-icon>
      {{ 'EXPERTISE.STOP' | translate }}
    </button>
    <ngx-toasted #toastContainer fxFlex="1 1 100%" class="w-100-p"></ngx-toasted>
  </div>
  <div class="bottom-actions-container">
    <app-actions
      (takePhotoClicked)="takePhoto()"
      (gotoGalleryClicked)="gotoGallery()"
      (switchCameraClicked)="switchCamera()"
      (toggleMicrophoneClicked)="toggleMicrophone()"></app-actions>
  </div>
  <input #gallery id="gallery" type="file" name="file" accept="image/png, image/jpeg" (change)="resumeFromGallery($event)">
</div>
