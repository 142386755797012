import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { TestComponent } from './views/test/test.component';
import { ExpertiseComponent } from './views/expertise/expertise.component';
import { LegalNoticeComponent } from './views/legal-notice/legal-notice.component';
import { IncompatibleBrowserComponent } from './views/incompatible-browser/incompatible-browser.component';
import { PostExpertiseComponent } from './views/post-expertise/post-expertise.component';
import { DirectAccessGuard } from './guards/direct-access.guard';
import { BrowserCompatibilityGuard } from './guards/browser-compatibility.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [BrowserCompatibilityGuard] },
  { path: 'test', component: TestComponent, canActivate: [BrowserCompatibilityGuard] },
  { path: 'expertise', component: ExpertiseComponent, canActivate: [BrowserCompatibilityGuard] },
  { path: 'legal-notice', component: LegalNoticeComponent, canActivate: [BrowserCompatibilityGuard] },
  { path: 'incompatible-browser', component: IncompatibleBrowserComponent, canActivate: [BrowserCompatibilityGuard] },
  { path: 'post-expertise', component: PostExpertiseComponent, canActivate: [DirectAccessGuard, BrowserCompatibilityGuard] },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [DirectAccessGuard, BrowserCompatibilityGuard]
})
export class AppRoutingModule { }
