import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { SharedModule } from '../shared.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    CookieService
  ],
})
export class LoginModule { }
