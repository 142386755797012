import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { WebRTCService } from '../../../services/webrtc.service';
import { Subscription } from 'rxjs';
import { BrowserCompatibilityService } from '../../../services/browser-compatibility.service';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss']
})
export class SubscriberComponent implements AfterViewInit, OnDestroy {

  @Input() stream: OT.Stream;
  @ViewChild('subscriberDiv', { static: false}) subscriberDiv: ElementRef;

  session: OT.Session;
  subscriptions: Subscription[] = [];

  subscriber: OT.Subscriber;

  constructor(
    public element: ElementRef,
    public webRTCService: WebRTCService,
    private browserCompatibilityService: BrowserCompatibilityService
  ) {

  }

  ngAfterViewInit(): void {
    let ratio;
    try {
      ratio = this.stream.videoDimensions.width / this.stream.videoDimensions.height;
    } catch (error) {
      ratio = 1.333; // Common ratio for webcam
    }
    const calculatedWidth = this.browserCompatibilityService.isMobile ? 160 : 240;
    const calculatedHeight = calculatedWidth / ratio;

    this.subscriber = this.webRTCService.subscribe(
      this.stream,
      this.subscriberDiv.nativeElement,
      {
          insertMode: 'replace',
          fitMode: 'contain',
          width: calculatedWidth,
          height: calculatedHeight,
          style: {
            nameDisplayMode: 'off',
            buttonDisplayMode: 'off',
            audioLevelDisplayMode: 'off',
            videoDisabledDisplayMode: 'on',
            archiveStatusDisplayMode: 'off'
          }
      } as any
    );
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.webRTCService.unsubscribe(this.subscriber);
    }

    this.subscriptions.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  public get isExpert(): boolean {
    return this.stream && this.stream.connection && this.stream.connection.data && this.stream.connection.data === 'type=expert';
  }

  public get isInsurant(): boolean {
    return this.stream && this.stream.connection && this.stream.connection.data && this.stream.connection.data === 'type=assure';
  }

  public get isGuest(): boolean {
    return this.stream && this.stream.connection && this.stream.connection.data && this.stream.connection.data === 'type=guest';
  }
}
