/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./basic-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/flex-layout/flex";
import * as i8 from "@angular/flex-layout/core";
import * as i9 from "@angular/common";
import * as i10 from "./basic-dialog.component";
var styles_BasicDialogComponent = [i0.styles];
var RenderType_BasicDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BasicDialogComponent, data: {} });
export { RenderType_BasicDialogComponent as RenderType_BasicDialogComponent };
function View_BasicDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.negativeClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.data.negativeButton; _ck(_v, 2, 0, currVal_2); }); }
export function View_BasicDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i6.MatDialogTitle, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["fxLayout", "row"], ["fxLayoutAlign", "end center"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i6.MatDialogActions, [], null, null), i1.ɵdid(8, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(9, 671744, null, 0, i7.DefaultLayoutAlignDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutAlignStyleBuilder], i8.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BasicDialogComponent_1)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.positiveClick();
        var pd_0 = ((_co.data.elementToClick ? _co.data.elementToClick.click() : null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(14, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = "row"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "end center"; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.data.negativeButton; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.content; _ck(_v, 5, 0, currVal_2); var currVal_6 = (i1.ɵnov(_v, 13).disabled || null); var currVal_7 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_6, currVal_7); var currVal_8 = _co.data.positiveButton; _ck(_v, 14, 0, currVal_8); }); }
export function View_BasicDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-basic-dialog", [], null, null, null, View_BasicDialogComponent_0, RenderType_BasicDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.BasicDialogComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA], null, null)], null, null); }
var BasicDialogComponentNgFactory = i1.ɵccf("app-basic-dialog", i10.BasicDialogComponent, View_BasicDialogComponent_Host_0, {}, {}, []);
export { BasicDialogComponentNgFactory as BasicDialogComponentNgFactory };
