import { Component, Output, EventEmitter, Input } from '@angular/core';
import { WebRTCService } from '../../../services/webrtc.service';

@Component({
  selector: 'app-pointer',
  templateUrl: './pointer.component.html',
  styleUrls: ['./pointer.component.scss']
})
export class PointerComponent {

  @Input() type = 'other';
  @Output() takePhotoClicked = new EventEmitter();
  @Output() gotoGalleryClicked = new EventEmitter();
  @Output() switchCameraClicked = new EventEmitter();
  @Output() toggleMicrophoneClicked = new EventEmitter();

  constructor(
    public webRTCService: WebRTCService
  ) {}

  public takePhoto() {
    this.takePhotoClicked.emit();
  }

  public gotoGallery() {
    this.gotoGalleryClicked.emit();
  }

  public switchCamera() {
    this.switchCameraClicked.emit();
  }

  public toggleMicrophone() {
    this.toggleMicrophoneClicked.emit();
  }
}
