export const environment = {
  name: 'prod',
  production: true,
  version   : require('../../package.json').version,
  api: {
    url: 'https://integration-apivisiowebrtc.polyexpert.fr/v1/'
  },
  log: {
    url: 'https://sauron.2dia.fr/log',
    token: '49aK9rBRA6LCtG4euJxsb62k2CX9j4h3'
  },
  opentok: {
    keyTest: '45722842',
    keySession: '45722832'
  },
  googleAnalytics: {
      domain: 'auto',
      trackingId: 'UA-145092754-1'
  }
};
