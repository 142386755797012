import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './views/login/login.module';
import { TestModule } from './views/test/test.module';
import { ExpertiseModule } from './views/expertise/expertise.module';
import { LegalNoticeModule } from './views/legal-notice/legal-notice.module';
import { IncompatibleBrowserModule } from './views/incompatible-browser/incompatible-browser.module';
import { BrowserCompatibilityService } from './services/browser-compatibility.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiService } from './services/api.service';
import { PostExpertiseModule } from './views/post-expertise/post-expertise.module';
import { NgxAnalyticsModule } from 'ngx-analytics';
import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';
import { ThemeService } from './services/theme.service';
import { PhoneService } from './services/phone.service';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';

Sentry.init({
  dsn: 'https://80ea97f2731c43c4bf908d364f73b558@sentry.2dia.fr/17',
  environment: environment.name,
  release: '' + require('../../package.json').name + '@' + environment.version
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (error.stack && error.stack.indexOf('/analytics.js') !== -1) {
      return;
    }
    Sentry.captureException(error.originalError || error);
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    LoginModule,
    TestModule,
    ExpertiseModule,
    PostExpertiseModule,
    LegalNoticeModule,
    IncompatibleBrowserModule,
    NgxAnalyticsModule.forRoot([NgxAnalyticsGoogleAnalytics], {
        pageTracking: {
            clearIds: true,
        }
    })
  ],
  providers: [
    BrowserCompatibilityService,
    ApiService,
    ThemeService,
    PhoneService,
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
