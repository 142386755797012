import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import Compressor from 'compressorjs';
import { PhoneService } from './phone.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./phone.service";
var ApiService = /** @class */ (function () {
    function ApiService(http, phoneService) {
        this.http = http;
        this.phoneService = phoneService;
        this.url = environment.api.url;
    }
    ApiService.prototype.getTestRoom = function () {
        return this.http.get(this.url + 'sessiontest').pipe();
    };
    ApiService.prototype.getExpertiseRoom = function (phone, name) {
        var formattedPhone = this.phoneService.formatPhoneForApi(phone);
        return this.http.post(this.url + 'session/' + formattedPhone + '/type/assure', { nom: name }).pipe();
    };
    ApiService.prototype.sendImage = function (phone, image, compressImage, compressionOptions) {
        var _this = this;
        if (compressImage === void 0) { compressImage = true; }
        if (compressionOptions === void 0) { compressionOptions = {
            maxHeight: 2000,
            maxWidth: 2000,
            quality: 0.8,
            mimeType: 'image/jpeg',
            checkOrientation: true
        }; }
        var formattedPhone = this.phoneService.formatPhoneForApi(phone);
        var formData = new FormData();
        if (compressImage) {
            return new Observable(function (observer) {
                _this.compressAndResize(image, compressionOptions).subscribe(function (compressed) {
                    formData.append('img', compressed);
                    _this.http.post(_this.url + 'session/' + formattedPhone + '/image', formData).subscribe(function (o) {
                        observer.next(o);
                        observer.complete();
                    });
                }, function (err) {
                    console.error(err);
                    formData.append('img', image);
                    _this.http.post(_this.url + 'session/' + formattedPhone + '/image', formData).subscribe(function (o) {
                        observer.next(o);
                        observer.complete();
                    });
                });
            });
        }
        else {
            formData.append('img', image);
            return this.http.post(this.url + 'session/' + formattedPhone + '/image', formData).pipe();
        }
    };
    ApiService.prototype.sendTestResult = function (phone, testResult) {
        var formattedPhone = this.phoneService.formatPhoneForApi(phone);
        return this.http.post(this.url + 'session/' + formattedPhone + '/testconnexion', testResult).pipe();
    };
    ApiService.prototype.sendDeviceInfo = function (phone, deviceInfo) {
        var formattedPhone = this.phoneService.formatPhoneForApi(phone);
        return this.http.post(this.url + 'session/' + formattedPhone + '/device', deviceInfo).pipe();
    };
    ApiService.prototype.log = function (type, message, data) {
        var body = {
            type: type,
            message: message,
            data: data
        };
        this.http.post(environment.log.url, body, {
            headers: {
                'Api-Token': environment.log.token
            }
        }).subscribe(function (o) {
            console.log((type && type.length > 0 ? type.charAt(0).toUpperCase() + type.slice(1) : type) + ' logged successfully');
        }, function (err) {
            if (type === 'error') {
                console.error('Oooh sweet inrony...', err);
            }
            else {
                console.error('Error while logging ' + type, err);
            }
        });
    };
    ApiService.prototype.compressAndResize = function (image, options) {
        return new Observable(function (observer) {
            options.success = function (compressed) {
                if (typeof image.name === 'string') {
                    var fileImage = image;
                    observer.next(new File([compressed], fileImage.name, {
                        lastModified: fileImage.lastModified,
                        type: compressed.type,
                    }));
                }
                else {
                    observer.next(compressed);
                }
                observer.complete();
            };
            options.error = function (error) {
                observer.error(error);
            };
            /* tslint:disable */
            var compressor = new Compressor(image, options);
            /* tslint:enable */
        });
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PhoneService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
