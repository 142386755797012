import { NgModule } from '@angular/core';
import { ExpertiseComponent } from './expertise.component';
import { SharedModule } from '../shared.module';
import { PublisherComponent } from './publisher/publisher.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { ActionsComponent } from './actions/actions.component';
import { LadDialogComponent } from './lad-dialog/lad-dialog.component';
import { PointerComponent } from './pointer/pointer.component';
import { NgxToastedModule } from 'ngx-toasted';

@NgModule({
  declarations: [
    ExpertiseComponent,
    PublisherComponent,
    SubscriberComponent,
    ActionsComponent,
    LadDialogComponent,
    PointerComponent
  ],
  imports: [
    SharedModule,
    NgxToastedModule
  ],
  entryComponents: [
    LadDialogComponent
  ]
})
export class ExpertiseModule { }
