import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Room } from '../models/api/room.interface';
import { Observable } from 'rxjs';
import { TestResult } from '../models/api/test-result.interface';
import { DeviceInfo } from '../models/api/device-info.interface';
import Compressor from 'compressorjs';
import { PhoneService } from './phone.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url: string = environment.api.url;

  constructor(
    private http: HttpClient,
    private phoneService: PhoneService
  ) { }

  public getTestRoom(): Observable<Room> {
    return this.http.get<Room>(this.url + 'sessiontest').pipe();
  }

  public getExpertiseRoom(phone: string, name: string): Observable<Room> {
    const formattedPhone = this.phoneService.formatPhoneForApi(phone);
    return this.http.post<Room>(this.url + 'session/' + formattedPhone + '/type/assure', { nom: name }).pipe();
  }

  public sendImage(
    phone: string,
    image: File,
    compressImage: boolean = true,
    compressionOptions: Compressor.Options = {
      maxHeight: 2000,
      maxWidth: 2000,
      quality: 0.8,
      mimeType: 'image/jpeg',
      checkOrientation: true
    }
  ): Observable<any> {
    const formattedPhone = this.phoneService.formatPhoneForApi(phone);
    const formData: FormData = new FormData();
    if (compressImage) {
      return new Observable<any>(observer => {
        this.compressAndResize(image, compressionOptions).subscribe((compressed: File) => {
          formData.append('img', compressed);
          this.http.post<any>(this.url + 'session/' + formattedPhone + '/image', formData).subscribe((o) => {
            observer.next(o);
            observer.complete();
          });
        }, (err) => {
          console.error(err);
          formData.append('img', image);
          this.http.post<any>(this.url + 'session/' + formattedPhone + '/image', formData).subscribe((o) => {
            observer.next(o);
            observer.complete();
          });
        });
      });
    } else {
      formData.append('img', image);
      return this.http.post<any>(this.url + 'session/' + formattedPhone + '/image', formData).pipe();
    }
  }

  public sendTestResult(phone: string, testResult: TestResult): Observable<any> {
    const formattedPhone = this.phoneService.formatPhoneForApi(phone);
    return this.http.post<Room>(this.url + 'session/' + formattedPhone + '/testconnexion', testResult).pipe();
  }

  public sendDeviceInfo(phone: string, deviceInfo: DeviceInfo): Observable<any> {
    const formattedPhone = this.phoneService.formatPhoneForApi(phone);
    return this.http.post<Room>(this.url + 'session/' + formattedPhone + '/device', deviceInfo).pipe();
  }

  public log(type: string, message: string, data: any) {
    const body = {
      type,
      message,
      data
    };

    this.http.post(
      environment.log.url,
      body,
      {
        headers: {
          'Api-Token': environment.log.token
        }
      }
    ).subscribe((o) => {
      console.log((type && type.length > 0 ? type.charAt(0).toUpperCase() + type.slice(1) : type) + ' logged successfully');
    }, (err) => {
      if (type === 'error') {
        console.error('Oooh sweet inrony...', err);
      } else {
        console.error('Error while logging ' + type, err);
      }
    });
  }

  private compressAndResize(image: File | Blob, options: Compressor.Options): Observable<File | Blob> {
    return new Observable<Blob>(observer => {
      options.success = (compressed: Blob) => {
        if (typeof (image as any).name === 'string') {
          const fileImage: File = image as any;
          observer.next(new File([compressed], fileImage.name, {
            lastModified: fileImage.lastModified,
            type: compressed.type,
          }));
        } else {
          observer.next(compressed);
        }
        observer.complete();
      };
      options.error = (error: Error) => {
        observer.error(error);
      };
      /* tslint:disable */
      const compressor = new Compressor(image, options);
      /* tslint:enable */
    });
  }
}
