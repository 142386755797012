<div class="card-page">
  <mat-card>
    <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="24px" class="m-16">
      <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
        <app-full-logo></app-full-logo>

        <div fxLayout="column" fxLayoutAlign="center stretch">
          <h2>{{ 'POST_EXPERTISE.TITLE' | translate }}</h2>
          <p [innerHTML]="'POST_EXPERTISE.CONTENT' | translate"></p>

          <form *ngIf="feedbackActivated" [formGroup]="form" [hidden]="feedbackValidated" class="bordered-container">
            <h4>{{ 'POST_EXPERTISE.FORM.TITLE' | translate }}</h4>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="32px">
              <div fxLayout="column" fxLayoutAlign="center center">
                <button type="button" mat-icon-button aria-label="Thumb up" (click)="setSatisfied(true)"
                  class="positive-feedback-button" [class.selected]="form.get('satisfied').value === true">
                  <mat-icon>thumb_up</mat-icon>
                </button>
              </div>

              <div fxLayout="column" fxLayoutAlign="center center">
                <button type="button" mat-icon-button aria-label="Thumb down" (click)="setSatisfied(false)"
                  class="negative-feedback-button" [class.selected]="form.get('satisfied').value === false">
                  <mat-icon>thumb_down</mat-icon>
                </button>
              </div>
            </div>

            <div [hidden]="form.get('satisfied').value !== false" class="mt-32">
              <div>
                <mat-checkbox formControlName="expertiseIssue">{{
                    'POST_EXPERTISE.FORM.PROBLEM_WITH_EXPERTISE' | translate
                  }}</mat-checkbox>
              </div>
              <div class="mt-16">
                <mat-checkbox formControlName="technicalIssue">{{
                    'POST_EXPERTISE.FORM.PROBLEM_TECHNIQUE' | translate
                  }}</mat-checkbox>
              </div>
              <div class="mt-16 mb-32">
                <mat-checkbox formControlName="otherIssue">{{
                    'POST_EXPERTISE.FORM.PROBLEM_OTHER' | translate
                  }}</mat-checkbox>
              </div>

              <label><strong>{{ 'POST_EXPERTISE.FORM.COMMENT_LABEL' | translate }}</strong></label>
              <mat-form-field class="mt-8">
                <mat-label>{{ 'POST_EXPERTISE.FORM.COMMENT_HINT' | translate }}</mat-label>
                <textarea matInput formControlName="comment"></textarea>
              </mat-form-field>
            </div>
          </form>

          <div *ngIf="feedbackActivated" class="greetings bordered-container" [hidden]="!feedbackValidated">
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon>sentiment_satisfied_alt</mat-icon>
              <p [innerHTML]="'POST_EXPERTISE.GREETINGS' | translate"></p>
            </div>
          </div>

          <button *ngIf="feedbackActivated && !feedbackValidated" mat-stroked-button color="primary"
            class="mt-8 w-100-p" (click)="validateFeedback()" [disabled]="form.get('satisfied').value === null">
            {{ 'POST_EXPERTISE.FORM.VALIDATE_FEEDBACK' | translate }}
          </button>

          <button mat-raised-button color="primary" class="mt-32 w-100-p" (click)="back()">
            {{ 'POST_EXPERTISE.BACK_BUTTON' | translate }}
          </button>
        </div>
      </div>

      <app-copyright></app-copyright>
    </div>
  </mat-card>
</div>
