import { Component } from '@angular/core';
import { BrowserCompatibilityService } from '../../services/browser-compatibility.service';

@Component({
  selector: 'app-incompatible-browser',
  templateUrl: './incompatible-browser.component.html',
  styleUrls: ['./incompatible-browser.component.scss']
})
export class IncompatibleBrowserComponent {
  constructor(
    public browserCompatibilityService: BrowserCompatibilityService
  ) { }
}
