import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { PostExpertiseComponent } from './post-expertise.component';

@NgModule({
  declarations: [
    PostExpertiseComponent
  ],
  imports: [
    SharedModule
  ],
})
export class PostExpertiseModule {  }
