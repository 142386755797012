<div class="card-page">
  <mat-card>
    <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="24px" class="m-16">
      <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
        <app-full-logo></app-full-logo>

        <div>
          <h2>{{ 'LEGAL_NOTICE.TITLE' | translate }}</h2>
          <p [innerHTML]="'LEGAL_NOTICE.CONTENT' | translate"></p>
        </div>

        <div>
          <a [href]="'LEGAL_NOTICE.BRAND_URL' | translate" target="_blank">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>link</mat-icon>&nbsp;{{ 'LEGAL_NOTICE.BRAND_TEXT_LINK' | translate }}
            </div>
          </a>
          <a [href]="'LEGAL_NOTICE.GC_URL' | translate" target="_blank">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>link</mat-icon>&nbsp;{{ 'LEGAL_NOTICE.GC_TEXT_LINK' | translate }}
            </div>
          </a>
        </div>
      </div>

      <app-copyright [legalNotice]="false"></app-copyright>
    </div>
  </mat-card>
</div>
