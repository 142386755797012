import { Component, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '../../services/utils.class';
import { UserInfo } from '../../models/user-info.interface';
import { CookieService } from 'ngx-cookie-service';
import { PhoneService } from '../../services/phone.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  form: FormGroup;
  private subscriptions: Subscription[] = [];

  dummyStatus = false;
  constructor(
      private router: Router,
      private cookieService: CookieService,
      private route: ActivatedRoute,
      private phoneService: PhoneService
    ) {
    let paramName: string;
    let paramPhone: string;
    try {
      const base64 = this.route.snapshot.queryParams.u;
      const name = this.route.snapshot.queryParams.name;
      const phone = this.route.snapshot.queryParams.phone;
      if (base64) {
        const userInfo = Utils.deserializeNameAndPhone(base64);
        if (userInfo.name && userInfo.phone) {
          paramName = userInfo.name;
          paramPhone = this.phoneService.formatPhoneForUser(userInfo.phone);
        }
      } else if (name && phone) {
        paramName = name;
        paramPhone = this.phoneService.formatPhoneForUser(phone);
      }
    } catch (error) { }

    let cookieName: string;
    let cookiePhone: string;
    try {
      if (this.cookieService.check('name')) {
        cookieName = this.cookieService.get('name');
      }

      if (this.cookieService.check('phone')) {
        cookiePhone = this.phoneService.formatPhoneForUser(this.cookieService.get('phone'));
      }
    } catch (error) { }

    this.form = new FormGroup({
      name: new FormControl(
        paramName ? paramName : (cookieName ? cookieName : null),
        [Validators.required]
      ),
      phone: new FormControl(
        paramPhone ? paramPhone : (cookiePhone ? cookiePhone : null),
        [Validators.required, this.phoneService.phoneNumberValidator()]
      )
    });

    let oldValue = '';
    this.subscriptions.push(
      this.form.get('phone').valueChanges.subscribe((value: string) => {
        if (oldValue.length > value.length && isNaN(+oldValue.slice(-1))) {
          // Non number characters deletion, do not interfer
          oldValue = value;
          return;
        }

        const formattedPhoneNumber = this.phoneService.formatPhoneForUserAsYouType(value);
        if (formattedPhoneNumber !== value) {
          this.form.get('phone').setValue(formattedPhoneNumber, { emitEvent: false });
          oldValue = formattedPhoneNumber;
        } else {
          oldValue = value;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  onTestClicked() {
    if (!this.form.valid) {
      return;
    }

    const phoneFormatted = this.phoneService.formatPhoneForApplication(this.form.get('phone').value);
    const base64 = Utils.serializeNameAndPhone(
      {
        name: this.form.get('name').value,
        phone: phoneFormatted
      } as UserInfo
    );

    try {
      this.cookieService.set('name', this.form.get('name').value);
      this.cookieService.set('phone', phoneFormatted);
    } catch (err) {
      console.error(err);
    }

    this.router.navigate(['test'], { queryParams: { u: base64 } });
  }

  onExpertiseClicked() {
    if (!this.form.valid) {
      return;
    }

    const phoneFormatted = this.phoneService.formatPhoneForApplication(this.form.get('phone').value);
    const base64 = Utils.serializeNameAndPhone(
      {
        name: this.form.get('name').value,
        phone: phoneFormatted
      } as UserInfo
    );

    try {
      this.cookieService.set('name', this.form.get('name').value);
      this.cookieService.set('phone', phoneFormatted);
    } catch (err) {
      console.error(err);
    }

    this.router.navigate(['expertise'], { queryParams: { u: base64 } });
  }

  onKeyPressed(event) {
    // Omit illegal charaters
    const key = event.charCode;
    const illegalCharacters = [58]; // 58 === ':'
    return illegalCharacters.indexOf(key) === -1;
  }
}
