import { ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { WebRTCService } from '../../../services/webrtc.service';
import { BrowserCompatibilityService } from '../../../services/browser-compatibility.service';
var SubscriberComponent = /** @class */ (function () {
    function SubscriberComponent(element, webRTCService, browserCompatibilityService) {
        this.element = element;
        this.webRTCService = webRTCService;
        this.browserCompatibilityService = browserCompatibilityService;
        this.subscriptions = [];
    }
    SubscriberComponent.prototype.ngAfterViewInit = function () {
        var ratio;
        try {
            ratio = this.stream.videoDimensions.width / this.stream.videoDimensions.height;
        }
        catch (error) {
            ratio = 1.333; // Common ratio for webcam
        }
        var calculatedWidth = this.browserCompatibilityService.isMobile ? 160 : 240;
        var calculatedHeight = calculatedWidth / ratio;
        this.subscriber = this.webRTCService.subscribe(this.stream, this.subscriberDiv.nativeElement, {
            insertMode: 'replace',
            fitMode: 'contain',
            width: calculatedWidth,
            height: calculatedHeight,
            style: {
                nameDisplayMode: 'off',
                buttonDisplayMode: 'off',
                audioLevelDisplayMode: 'off',
                videoDisabledDisplayMode: 'on',
                archiveStatusDisplayMode: 'off'
            }
        });
    };
    SubscriberComponent.prototype.ngOnDestroy = function () {
        if (this.subscriber) {
            this.webRTCService.unsubscribe(this.subscriber);
        }
        this.subscriptions.forEach(function (s) {
            s.unsubscribe();
        });
    };
    Object.defineProperty(SubscriberComponent.prototype, "isExpert", {
        get: function () {
            return this.stream && this.stream.connection && this.stream.connection.data && this.stream.connection.data === 'type=expert';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubscriberComponent.prototype, "isInsurant", {
        get: function () {
            return this.stream && this.stream.connection && this.stream.connection.data && this.stream.connection.data === 'type=assure';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubscriberComponent.prototype, "isGuest", {
        get: function () {
            return this.stream && this.stream.connection && this.stream.connection.data && this.stream.connection.data === 'type=guest';
        },
        enumerable: true,
        configurable: true
    });
    return SubscriberComponent;
}());
export { SubscriberComponent };
