import { Component, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '../../services/utils.class';
import { WebRTCService, DevicePermission } from '../../services/webrtc.service';
import { ApiService } from '../../services/api.service';
import { Room } from '../../models/api/room.interface';
import { environment } from '../../../environments/environment';
import NetworkTest from 'opentok-network-test-js';
import { BrowserCompatibilityService } from '../../services/browser-compatibility.service';
import { UserInfo } from '../../models/user-info.interface';
import { TestResult } from '../../models/api/test-result.interface';
import { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import { PhoneService } from '../../services/phone.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  providers: [WebRTCService]
})
export class TestComponent implements OnDestroy {
  name: string;
  phone: string;

  DevicePermission = DevicePermission;

  isTesting = false;
  testProgress = 0;
  testMode = 'indeterminate';
  testingTime = 20000;
  testStatus = '';
  testRemainingTime = undefined;

  isResult: boolean = undefined;
  resultString: string = undefined;

  resultSpeedVideo: number = undefined;
  resultSpeedAudio: number = undefined;
  resultPacketLostVideo: number = undefined;
  resultPacketLostAudio: number = undefined;

  private currentOtNetworkTest: NetworkTest = undefined;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private phoneService: PhoneService,
    private ref: ChangeDetectorRef,
    public browserCompatibilityService: BrowserCompatibilityService,
    public webRTCService: WebRTCService
  ) {
    try {
      const base64 = this.route.snapshot.queryParams.u;
      const name = this.route.snapshot.queryParams.name;
      const phone = this.route.snapshot.queryParams.phone;
      if (base64) {
        const userInfo = Utils.deserializeNameAndPhone(base64);
        if (userInfo.name && userInfo.phone) {
          this.name = userInfo.name;
          this.phone = this.phoneService.formatPhoneForApplication(userInfo.phone);
        } else {
          throw new Error('Bad user info');
        }
      } else if (name && phone) {
        this.name = name;
        this.phone = this.phoneService.formatPhoneForApplication(phone);
      } else {
        throw new Error('Missing user info');
      }
    } catch (error) {
      this.router.navigate(['login']);
    }
  }

  ngOnDestroy(): void {
    this.stopTest();
  }

  launchTest() {
    const self = this;

    try {
      self.setProgress(0, undefined, false);
      self.setResult(
        true,
        undefined,
        'TEST.CONNECTING',
        undefined
      );

      this.apiService.getTestRoom().subscribe((room: Room) => {
        const existingPublisher = this.webRTCService.currentPublisherObject;
        if (existingPublisher) {
          existingPublisher.destroy();
        }

        this.webRTCService.onTestStart.emit();

        const otNetworkTest = new NetworkTest(this.webRTCService.getOT() as any, {
          apiKey: environment.opentok.keyTest,
          sessionId: room.id,
          token: room.token
        }, {
          audioOnly: false,
          timeout: self.testingTime
        });

        this.currentOtNetworkTest = otNetworkTest;

        let count = 0;

        otNetworkTest.testConnectivity().then((connectivityRes) => {
          self.setResult(
            true,
            undefined,
            'TEST.TESTING',
            undefined
          );
          otNetworkTest.testQuality((testRes) => {
            count++;
            const timePassed = count * 1000;
            const progression = (timePassed / self.testingTime) * 100;
            self.setProgress(progression, (self.testingTime - timePassed) / 1000, true);
          }).then((results: QualityTestResults) => {
            console.log('Test result', results);

            let code;
            if (!results.video.supported && !results.audio.supported) {
              code = 102;
              self.setResult(
                false,
                false,
                'TEST.DONE',
                'TEST.RESULT.VIDEO_AND_AUDIO_NOT_SUPPORTED',
                results.video.bitrate, results.audio.bitrate, results.video.packetLossRatio, results.audio.packetLossRatio
              );
            } else if (!results.video.supported) {
              code = 102;
              self.setResult(
                false,
                false,
                'TEST.DONE',
                'TEST.RESULT.VIDEO_NOT_SUPPORTED',
                results.video.bitrate, results.audio.bitrate, results.video.packetLossRatio, results.audio.packetLossRatio
              );
            } else if (!results.audio.supported) {
              code = 102;
              self.setResult(
                false,
                false,
                'TEST.DONE',
                'TEST.RESULT.AUDIO_NOT_SUPPORTED',
                results.video.bitrate, results.audio.bitrate, results.video.packetLossRatio, results.audio.packetLossRatio
              );
            } else if (results.video.reason) {
              code = 102;
              self.setResult(
                false,
                false,
                'TEST.DONE',
                'TEST.RESULT.NOT_ENOUGH',
                results.video.bitrate, results.audio.bitrate, results.video.packetLossRatio, results.audio.packetLossRatio
              );
            } else if (results.audio.reason) {
              code = 101;
              self.setResult(
                false,
                false,
                'TEST.DONE',
                'TEST.RESULT.AUDIO_ONLY',
                results.video.bitrate, results.audio.bitrate, results.video.packetLossRatio, results.audio.packetLossRatio
              );
            } else {
              code = 100;
              self.setResult(
                false,
                true,
                'TEST.DONE',
                'TEST.RESULT.OK',
                results.video.bitrate, results.audio.bitrate, results.video.packetLossRatio, results.audio.packetLossRatio
              );
            }
            self.setProgress(0, undefined, false);
            this.currentOtNetworkTest = undefined;
            this.onTestResult(code, results);
            this.webRTCService.onTestEnd.emit();
          }).catch((err) => {
            this.onTestError(err, self);
          });
        }).catch((err) => {
          this.onTestError(err, self);
        });
      }, (err) => {
        this.onTestError(err, self);
      });
    } catch (err) {
      this.onTestError(err, self);
    }
  }

  private onTestError(err, self: TestComponent) {
    console.error(err);
    self.setResult(false, false, 'TEST.DONE', 'TEST.RESULT.CONNECTIVITY_ERROR');
    self.setProgress(0, undefined, false);
    self.currentOtNetworkTest = undefined;
    self.onTestResult(103);
    self.webRTCService.onTestEnd.emit();
  }

  stopTest() {
    if (this.currentOtNetworkTest) {
      this.currentOtNetworkTest.stop();
    }
  }

  startExpertise() {
    const base64 = Utils.serializeNameAndPhone(
      {
        name: this.name,
        phone: this.phone
      } as UserInfo
    );
    this.router.navigate(['expertise'], { queryParams: { u: base64 } });
  }

  private setResult(
    isTesting: boolean,
    isResult: boolean,
    status: string,
    text: string,
    resultSpeedVideo?,
    resultSpeedAudio?,
    resultPacketLostVideo?,
    resultPacketLostAudio?,
  ) {
    this.ngZone.run(() => {
      this.isTesting = isTesting;
      this.isResult = isResult;
      this.testStatus = status;
      this.resultString = text;
      this.resultSpeedVideo = resultSpeedVideo;
      this.resultSpeedAudio = resultSpeedAudio;
      this.resultPacketLostVideo = resultPacketLostVideo;
      this.resultPacketLostAudio = resultPacketLostAudio;
    });
  }

  private setProgress(
    progression: number,
    remainingTime: number,
    determinate: boolean
  ) {
    this.ngZone.run(() => {
      this.testProgress = progression;
      this.testRemainingTime = remainingTime;
      this.testMode = determinate ? 'determinate' : 'indeterminate';
    });
  }

  private onTestResult(code: number, results?: QualityTestResults) {
    this.apiService.sendTestResult(
      this.phone,
      {
        plateform: 'Web',
        result: code,
        audioBw: results && results.audio && results.audio.bitrate ? results.audio.bitrate : 0,
        videoBw: results && results.video && results.video.bitrate ? results.video.bitrate : 0,
        audioLoss: results && results.audio && results.audio.packetLossRatio ? results.audio.packetLossRatio : 0,
        videoLoss: results && results.video && results.video.packetLossRatio ? results.video.packetLossRatio : 0,
        network: 'Unknown',
        phoneType: this.browserCompatibilityService.getDeviceName()
      } as TestResult
    ).subscribe((res) => {
      console.log(res);
    }, (err) => {
      console.error(err);
    });
  }
}
