import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
var ThemeService = /** @class */ (function () {
    function ThemeService(cookieService) {
        this.cookieService = cookieService;
        this.STORAGE_KEY = 'user-color-scheme';
        this.COLOR_MODE_KEY = '--color-mode';
        this.isDarkTheme = new BehaviorSubject((this.getUserColorScheme() || this.getCSSCustomProp(this.COLOR_MODE_KEY)) === 'dark');
        this.applySetting();
    }
    ThemeService.prototype.getUserColorScheme = function () {
        return this.cookieService.check(this.STORAGE_KEY) ? this.cookieService.get(this.STORAGE_KEY) : null;
    };
    ThemeService.prototype.getCSSCustomProp = function (propKey) {
        var response = getComputedStyle(document.documentElement).getPropertyValue(propKey);
        if (response.length) {
            response = response.replace(/\"/g, '').trim();
        }
        return response;
    };
    ThemeService.prototype.applySetting = function (passedSetting) {
        var currentSetting = passedSetting || this.getUserColorScheme();
        if (currentSetting) {
            document.documentElement.setAttribute('data-user-color-scheme', currentSetting);
        }
        return currentSetting;
    };
    ThemeService.prototype.toggleSetting = function () {
        var currentSetting = this.getUserColorScheme();
        switch (currentSetting) {
            case null:
                currentSetting = this.getCSSCustomProp(this.COLOR_MODE_KEY) === 'dark' ? 'light' : 'dark';
                break;
            case 'light':
                currentSetting = 'dark';
                break;
            case 'dark':
                currentSetting = 'light';
                break;
        }
        this.cookieService.set(this.STORAGE_KEY, currentSetting);
        return currentSetting;
    };
    ThemeService.prototype.toggleTheme = function () {
        var newSetting = this.applySetting(this.toggleSetting());
        this.isDarkTheme.next(newSetting === 'dark');
    };
    ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.CookieService)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
