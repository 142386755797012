import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private supportedLang = ['en', 'fr'];

  constructor(
    private translateService: TranslateService,
    private ngxAnalyticsGoogleAnalytics: NgxAnalyticsGoogleAnalytics,
    private themeService: ThemeService
    ) {
    this.translateService.addLangs(this.supportedLang);
    this.translateService.setDefaultLang('en');
    this.translateService.use(this.translateService.getBrowserLang());

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  }
}
