import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { WebRTCService } from '../../../services/webrtc.service';
import { Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Publisher } from '@opentok/client';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss']
})
export class PublisherComponent implements OnDestroy, AfterViewInit {
  @ViewChild('publisherDiv', { static: false }) publisherDiv: ElementRef;

  session: OT.Session;
  subscriptions: Subscription[] = [];

  publisher: OT.Publisher;

  constructor(
    private webRTCService: WebRTCService,
  ) { }

  ngAfterViewInit() {
    this.publisher = this.webRTCService.initPublisher(this.publisherDiv.nativeElement, {
      publishAudio: this.webRTCService.hasAudio,
      publishVideo: this.webRTCService.hasVideo,
      insertMode: 'replace',
      fitMode: 'contain',
      width: '100%',
      height: '100%',
      mirror: false,
      style: {
        nameDisplayMode: 'off',
        buttonDisplayMode: 'off',
        audioLevelDisplayMode: 'off',
        archiveStatusDisplayMode: 'off',
        backgroundImageURI: 'on',
      }
    } as any);

    // Force environement facing, to change in publisher parameter if the sdk allow it one time
    this.publisher.once('videoElementCreated', (stream) => {
      this.publisher.cycleVideo();
    });

    const s = combineLatest(
      this.webRTCService.isConnectedOnSession.pipe(),
      this.webRTCService.currentPublisher.pipe(map((publisher: Publisher, index: number) => !!publisher))
    ).subscribe((res) => {
      if (res.every(value => value === true)) {
        this.webRTCService.publish();
        s.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.webRTCService.unpublish();
    this.subscriptions.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }
}
