import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var BrowserCompatibilityService = /** @class */ (function () {
    function BrowserCompatibilityService(translateService) {
        this.translateService = translateService;
        this.isChrome = /Google Inc/.test(navigator.vendor);
        this.isChromeIOS = /CriOS/.test(navigator.userAgent);
        this.isChromiumBased = !!window.chrome && !/Edge/.test(navigator.userAgent);
        this.isFirefox = /Firefox/.test(navigator.userAgent);
        this.isEdge = /Edge/.test(navigator.userAgent);
        this.isIE = /Trident/.test(navigator.userAgent);
        this.isOpera = /OPR/.test(navigator.userAgent);
        this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent);
        this.isSafariIOS = /(iPhone|iPad|iPod)/.test(navigator.platform)
            && /WebKit/i.test(window.navigator.userAgent)
            && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent));
        this.isMobile = /Android/.test(navigator.userAgent) || /(iPhone|iPad|iPod)/.test(navigator.platform);
        this.isAndroid = /Android/.test(navigator.userAgent);
        this.isIOS = /(iPhone|iPad|iPod)/.test(navigator.platform);
        this.isIphone = /(iPhone)/.test(navigator.platform);
        this.isIpad = /(iPad)/.test(navigator.platform);
        this.isIpod = /(iPod)/.test(navigator.platform);
        this.isCordova = !!window.cordova;
        this.device = undefined;
        try {
            var DeviceDetector = require('device-detector-js');
            var deviceDetector = new DeviceDetector();
            var device = deviceDetector.parse(navigator.userAgent);
            this.device = device;
        }
        catch (err) { }
    }
    BrowserCompatibilityService.prototype.isBrowserCompatible = function () {
        if (this.isAndroid) {
            return this.isChromiumBased;
        }
        else if (this.isIOS) {
            return this.isSafariIOS;
        }
        else {
            return this.isSafari || this.isChromiumBased || this.isFirefox;
        }
    };
    BrowserCompatibilityService.prototype.getDeviceName = function () {
        try {
            var device = this.device;
            if (device) {
                return [device.device.brand, device.device.model].filter(function (it) { return it && it.length > 0; }).join(' ') +
                    ' (' + device.os.name + ' ' + device.os.version + ') (web)';
            }
            else if (this.isIphone || this.isIpad) {
                var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                var version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)].join('.');
                return (this.isIphone ? 'iPhone' : 'iPad') + ' (iOS ' + version + ') (web)';
            }
            else if (this.isAndroid) {
                return 'Android (web)';
            }
            else {
                return this.translateService.instant('SHARED.UNKNOWN_DEVICE') + ' (web)';
            }
        }
        catch (error) {
            return this.translateService.instant('SHARED.UNKNOWN_DEVICE') + ' (web)';
        }
    };
    BrowserCompatibilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrowserCompatibilityService_Factory() { return new BrowserCompatibilityService(i0.ɵɵinject(i1.TranslateService)); }, token: BrowserCompatibilityService, providedIn: "root" });
    return BrowserCompatibilityService;
}());
export { BrowserCompatibilityService };
