import { NgModule } from '@angular/core';
import { TestComponent } from './test.component';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    TestComponent
  ],
  imports: [
    SharedModule
  ]
})
export class TestModule { }
