/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscriber.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./subscriber.component";
import * as i4 from "../../../services/webrtc.service";
import * as i5 from "../../../services/browser-compatibility.service";
var styles_SubscriberComponent = [i0.styles];
var RenderType_SubscriberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriberComponent, data: {} });
export { RenderType_SubscriberComponent as RenderType_SubscriberComponent };
export function View_SubscriberComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { subscriberDiv: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "content"]], [[2, "expert", null], [2, "guest", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["subscriberDiv", 1]], null, 0, "div", [["class", "subcriber-video-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isExpert; var currVal_1 = _co.isGuest; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform((_co.isExpert ? "EXPERTISE.SUBSCRIBER_HEADER_EXPERT" : (_co.isGuest ? "EXPERTISE.SUBSCRIBER_HEADER_GUEST" : "EXPERTISE.SUBSCRIBER_HEADER_OTHER")))); _ck(_v, 4, 0, currVal_2); }); }
export function View_SubscriberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subscriber", [], null, null, null, View_SubscriberComponent_0, RenderType_SubscriberComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SubscriberComponent, [i1.ElementRef, i4.WebRTCService, i5.BrowserCompatibilityService], null, null)], null, null); }
var SubscriberComponentNgFactory = i1.ɵccf("app-subscriber", i3.SubscriberComponent, View_SubscriberComponent_Host_0, { stream: "stream" }, {}, []);
export { SubscriberComponentNgFactory as SubscriberComponentNgFactory };
