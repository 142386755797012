import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-post-expertise',
  templateUrl: './post-expertise.component.html',
  styleUrls: ['./post-expertise.component.scss'],
})
export class PostExpertiseComponent implements OnDestroy {
  form: FormGroup;
  feedbackActivated = true;
  feedbackValidated = false;
  feedbackSent = false;

  constructor(
    private router: Router,
    private apiService: ApiService
  ) {
    this.form = new FormGroup({
      satisfied: new FormControl(null, []),
      technicalIssue: new FormControl(false, []),
      expertiseIssue: new FormControl(false, []),
      otherIssue: new FormControl(false, []),
      comment: new FormControl('', []),
    });
  }

  ngOnDestroy(): void {
    this.sendFeedback();
  }

  back() {
    this.router.navigate(['login']);
  }

  setSatisfied(value) {
    this.form.get('satisfied').setValue(value);
  }

  validateFeedback() {
    this.feedbackValidated = true;
    this.sendFeedback();
  }

  sendFeedback() {
    if (this.feedbackSent) {
      return;
    }
    this.feedbackSent = true;
    const feedbacks = this.form.getRawValue();
    if (feedbacks.satisfied !== null) {
      this.apiService.log('feedback', feedbacks.satisfied ? 'good' : 'bad', feedbacks);
    }
  }
}
