import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import * as i0 from "@angular/core";
var PhoneService = /** @class */ (function () {
    function PhoneService() {
        this.phoneRegion = 'FR';
        // tslint:disable-next-line: max-line-length
        this.acceptedPhoneRegion = ['001', 'AC', 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GT', 'GU', 'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TA', 'TC', 'TD', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];
        var navigatorRegion = navigator.language.split('-').pop().toUpperCase();
        if (this.acceptedPhoneRegion.indexOf(navigatorRegion) !== -1) {
            this.phoneRegion = navigatorRegion;
        }
    }
    PhoneService.prototype.formatPhoneForUser = function (phone) {
        var toReturn = phone;
        try {
            var phoneNumber = parsePhoneNumberFromString(phone, this.phoneRegion);
            toReturn = phoneNumber.formatNational();
        }
        catch (e) { }
        return toReturn;
    };
    PhoneService.prototype.formatPhoneForUserAsYouType = function (phone) {
        try {
            var formattedPhoneNumber = new AsYouType(this.phoneRegion).input(phone);
            if (formattedPhoneNumber) {
                return formattedPhoneNumber;
            }
            else {
                return phone;
            }
        }
        catch (error) {
            return phone;
        }
    };
    PhoneService.prototype.formatPhoneForApplication = function (phone) {
        var toReturn = phone;
        try {
            var phoneNumber = parsePhoneNumberFromString(phone, this.phoneRegion);
            toReturn = phoneNumber.format('E.164');
        }
        catch (e) { }
        return toReturn;
    };
    PhoneService.prototype.formatPhoneForApi = function (phone) {
        var toReturn = phone;
        try {
            var phoneNumber = parsePhoneNumberFromString(phone, this.phoneRegion);
            if (phoneNumber.country === 'FR') {
                toReturn = '0' + phoneNumber.nationalNumber;
            }
            else {
                toReturn = phoneNumber.format('E.164');
                if (toReturn.charAt(0) === '+') {
                    toReturn = '00' + toReturn.substr(1);
                }
            }
        }
        catch (e) { }
        return toReturn;
    };
    PhoneService.prototype.phoneNumberValidator = function () {
        var _this = this;
        return function (control) {
            var validNumber = false;
            try {
                var phoneNumber = parsePhoneNumberFromString(control.value, _this.phoneRegion);
                validNumber = phoneNumber.isValid();
            }
            catch (e) { }
            return validNumber ? null : { wrongNumber: { value: control.value } };
        };
    };
    PhoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneService_Factory() { return new PhoneService(); }, token: PhoneService, providedIn: "root" });
    return PhoneService;
}());
export { PhoneService };
