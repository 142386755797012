import { OnDestroy, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as OT from '@opentok/client';
export var DevicePermission;
(function (DevicePermission) {
    DevicePermission[DevicePermission["Undefined"] = 0] = "Undefined";
    DevicePermission[DevicePermission["NoDevicesFound"] = 1] = "NoDevicesFound";
    DevicePermission[DevicePermission["NoPermission"] = 2] = "NoPermission";
    DevicePermission[DevicePermission["Ok"] = 3] = "Ok";
})(DevicePermission || (DevicePermission = {}));
var WebRTCService = /** @class */ (function () {
    function WebRTCService() {
        this._mediaStream = null;
        this._session = new BehaviorSubject(null);
        this._currentPublisher = new BehaviorSubject(null);
        this._currentSubscribers = new BehaviorSubject([]);
        this._currentStreams = new BehaviorSubject([]);
        this._hasAudio = true;
        this._hasVideo = true;
        this.permissionsStatus = new BehaviorSubject(DevicePermission.Undefined);
        this.onSignalEventEmitter = new EventEmitter();
        this.onConnectionCreatedEmitter = new EventEmitter();
        this.onConnectionDestroyedEmitter = new EventEmitter();
        this.onSessionConnectedEmitter = new EventEmitter();
        this.onSessionDisconnectedEmitter = new EventEmitter();
        this.onSessionReconnectingEmitter = new EventEmitter();
        this.onSessionReconnectedEmitter = new EventEmitter();
        this.onPublisherVideoDimensionChangedEmitter = new EventEmitter();
        this.onPublisherAudioLevelUpdatedEmitter = new EventEmitter();
        this.onStreamCreatedEmitter = new EventEmitter();
        this.onStreamDestroyedEmitter = new EventEmitter();
        this.onTestStart = new EventEmitter();
        this.onTestEnd = new EventEmitter();
        this.isConnectedOnSession = new BehaviorSubject(false);
    }
    WebRTCService.prototype.getOT = function () {
        return OT;
    };
    WebRTCService.prototype.setRoom = function (apiKey, room) {
        var _this = this;
        this.room = room;
        this.apiKey = apiKey;
        this.sessionId = room.id;
        this.sessionToken = room.token;
        if (this.apiKey && this.sessionId && this.sessionToken) {
            var session_1 = this.getOT().initSession(this.apiKey, this.sessionId);
            if (session_1) {
                session_1.on('streamCreated', function (event) {
                    _this.addCurrentStreams(event.stream);
                    _this.onStreamCreatedEmitter.emit(event.stream);
                });
                session_1.on('streamDestroyed', function (event) {
                    _this.removeCurrentStreams(event.stream);
                    _this.onStreamDestroyedEmitter.emit(event.stream);
                });
                session_1.on('signal', function (event) {
                    if (session_1.connection.connectionId === event.from.connectionId) {
                        console.log('Signal emmited successfully', event);
                    }
                    else {
                        console.log('Signal received', event);
                        _this.onSignalEventEmitter.emit(event);
                    }
                });
                session_1.on('connectionCreated', function (event) {
                    console.log('Connection created', event);
                    _this.onConnectionCreatedEmitter.emit(event);
                });
                session_1.on('connectionDestroyed', function (event) {
                    console.log('Connection destroyed', event);
                    _this.onConnectionDestroyedEmitter.emit(event);
                });
                session_1.on('sessionConnected', function (event) {
                    console.log('Connected to session', event);
                    _this.isConnectedOnSession.next(true);
                    _this.onSessionConnectedEmitter.emit(event);
                });
                session_1.on('sessionDisconnected', function (event) {
                    console.log('Disconnected to session', event);
                    _this.isConnectedOnSession.next(false);
                    _this.onSessionDisconnectedEmitter.emit(event);
                });
                session_1.on('sessionReconnecting', function (event) {
                    console.log('Reconnecting to session...', event);
                    _this.isConnectedOnSession.next(false);
                    _this.onSessionReconnectingEmitter.emit(event);
                });
                session_1.on('sessionReconnected', function (event) {
                    console.log('Reconnected to session', event);
                    _this.isConnectedOnSession.next(true);
                    _this.onSessionReconnectedEmitter.emit(event);
                });
            }
            this._session.next(session_1);
            return Promise.resolve(session_1);
        }
        else {
            return Promise.reject('Something went wrong...');
        }
    };
    WebRTCService.prototype.connect = function () {
        var _this = this;
        if (!this.room) {
            return Promise.reject('No room specified');
        }
        var session = this._session.value;
        if (!session) {
            return Promise.reject('No session specified');
        }
        return new Promise(function (resolve, reject) {
            session.connect(_this.sessionToken, function (err) {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(session);
                }
            });
        });
    };
    WebRTCService.prototype.initPublisher = function (nativeElement, options) {
        var _this = this;
        var publisher = this.getOT().initPublisher(nativeElement, options, function (err) {
            if (err && err.name === 'OT_NO_DEVICES_FOUND') {
                if (_this.permissionsStatus.value !== DevicePermission.NoDevicesFound) {
                    _this.permissionsStatus.next(DevicePermission.NoDevicesFound);
                }
            }
        });
        publisher.on('accessAllowed', function (event) {
            if (_this.permissionsStatus.value !== DevicePermission.Ok) {
                _this.permissionsStatus.next(DevicePermission.Ok);
            }
        });
        publisher.on('accessDenied', function (event) {
            if (_this.permissionsStatus.value !== DevicePermission.NoPermission) {
                _this.permissionsStatus.next(DevicePermission.NoPermission);
            }
        });
        publisher.on('audioLevelUpdated', function (event) {
            _this.onPublisherAudioLevelUpdatedEmitter.emit(event);
        });
        publisher.on('videoDimensionsChanged', function (event) {
            _this.onPublisherVideoDimensionChangedEmitter.emit(event);
        });
        this.setCurrentPublisher(publisher);
        return publisher;
    };
    WebRTCService.prototype.publish = function () {
        var session = this._session.value;
        if (!session || !session.connection) {
            console.error('Trying to publish while not being connected');
            return;
        }
        var publisher = this._currentPublisher.value;
        if (!publisher) {
            console.error('Trying to publish while no publisher has been initialized');
            return;
        }
        session.publish(publisher, function (err) {
            if (err) {
                console.error(err);
            }
        });
    };
    WebRTCService.prototype.unpublish = function () {
        var session = this._session.value;
        var publisher = this._currentPublisher.value;
        if (session && session.connection && publisher) {
            session.unpublish(publisher);
            this.setCurrentPublisher(null);
        }
    };
    WebRTCService.prototype.subscribe = function (stream, targetElement, properties) {
        var _this = this;
        var session = this._session.value;
        if (!session || !session.connection) {
            throw new Error('Trying to subscribe while not being connected');
        }
        var subscriber = session.subscribe(stream, targetElement, properties, function (err) {
            if (err) {
                console.error(err.message);
            }
            else {
                _this.addCurrenSubscribers(subscriber);
            }
        });
        return subscriber;
    };
    WebRTCService.prototype.unsubscribe = function (subscriber) {
        var session = this._session.value;
        if (session && session.connection) {
            session.unsubscribe(subscriber);
        }
        this.removeCurrenSubscribers(subscriber);
    };
    WebRTCService.prototype.disconnect = function () {
        var session = this._session.value;
        if (session) {
            session.disconnect();
        }
    };
    WebRTCService.prototype.ngOnDestroy = function () {
        this.disconnect();
        try {
            var mediaStream = this._mediaStream;
            if (mediaStream) {
                mediaStream.getTracks().forEach(function (track) {
                    track.stop();
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    };
    Object.defineProperty(WebRTCService.prototype, "currentSession", {
        get: function () {
            return this._session;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebRTCService.prototype, "currentSessionObject", {
        get: function () {
            return this._session.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebRTCService.prototype, "currentPublisher", {
        get: function () {
            return this._currentPublisher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebRTCService.prototype, "currentPublisherObject", {
        get: function () {
            return this._currentPublisher.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebRTCService.prototype, "currentStreams", {
        get: function () {
            return this._currentStreams;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebRTCService.prototype, "currentSubscribers", {
        get: function () {
            return this._currentSubscribers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebRTCService.prototype, "hasAudio", {
        get: function () {
            return this._hasAudio;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebRTCService.prototype, "hasVideo", {
        get: function () {
            return this._hasVideo;
        },
        enumerable: true,
        configurable: true
    });
    WebRTCService.prototype.toggleAudio = function () {
        var publisher = this._currentPublisher.value;
        if (publisher) {
            this._hasAudio = !this._hasAudio;
            publisher.publishAudio(this._hasAudio);
        }
    };
    WebRTCService.prototype.toggleVideo = function () {
        var publisher = this._currentPublisher.value;
        if (publisher) {
            this._hasVideo = !this._hasVideo;
            publisher.publishVideo(this._hasVideo);
        }
    };
    WebRTCService.prototype.setCurrentPublisher = function (publisher) {
        if (this._currentPublisher.value) {
            this._currentPublisher.value.destroy();
        }
        this._currentPublisher.next(publisher);
    };
    WebRTCService.prototype.addCurrenSubscribers = function (subscriber) {
        var newVal = this._currentSubscribers.getValue();
        if (newVal == null) {
            newVal = [];
        }
        newVal.push(subscriber);
        this._currentSubscribers.next(newVal);
        if (subscriber) {
            subscriber.on('connected', function (event) {
                console.log('Subscriber connected', event);
            });
            subscriber.on('disconnected', function (event) {
                console.log('Subscriber disconnected', event);
            });
            subscriber.on('destroyed', function (event) {
                console.log('Subscriber destroyed', event);
            });
        }
    };
    WebRTCService.prototype.removeCurrenSubscribers = function (subscriber) {
        var newVal = this._currentSubscribers.getValue();
        if (newVal == null) {
            newVal = [];
        }
        var idx = newVal.indexOf(subscriber);
        if (idx > -1) {
            newVal.splice(idx, 1);
            this._currentSubscribers.next(newVal);
        }
        subscriber.off('connected', null);
        subscriber.off('disconnected', null);
        subscriber.off('destroyed', null);
    };
    WebRTCService.prototype.putStreamOnTop = function (stream) {
        var streams = this._currentStreams.getValue();
        var index = streams.findIndex(function (s) { return stream.streamId === s.streamId; });
        if (index !== -1) {
            streams.splice(index, 1);
            streams.push(stream);
            this._currentStreams.next(streams);
        }
    };
    WebRTCService.prototype.addCurrentStreams = function (stream) {
        var newVal = this._currentStreams.getValue();
        if (newVal == null) {
            newVal = [];
        }
        newVal.push(stream);
        this._currentStreams.next(newVal);
    };
    WebRTCService.prototype.removeCurrentStreams = function (stream) {
        var newVal = this._currentStreams.getValue();
        if (newVal == null) {
            newVal = [];
        }
        var idx = newVal.indexOf(stream);
        if (idx > -1) {
            newVal.splice(idx, 1);
            this._currentStreams.next(newVal);
        }
    };
    return WebRTCService;
}());
export { WebRTCService };
